@import '~scss/variables.scss';

.control-menu-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 0 10px 10px;
  .control-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon-aquaStyle {
      padding: 8px;
      background-color: rgba(16, 77, 157, 0.8);
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s;
      svg, img {
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: rgba(5, 32, 68, 0.8);
      }
    }
  }
  
  @media (max-width: 575px) {
    left: 0;
    bottom: 45px;
    .control-buttons {
      justify-content: center;
    }
  }

  @media (max-width: 360px) {
    .control-buttons, .mapfloorplan_container {
      display: none;
    }
  }
}
