.carousel-root {
  width: 100%;

  .slide {
    display: flex;
    justify-content: center;
  }

  .control-dots {
    position: fixed;
    bottom: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    user-select: none;
  }
}

.arrow {
  display: inline-block;
  position: fixed;
  top: 50%;
  width: 60px;
  height: 60px;
  background: transparent;
  text-indent: -9999px;
  border-top: 2px solid #bfbfbf;
  border-left: 2px solid #bfbfbf;
  border-bottom: 0;
  border-right: 0;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  color: transparent;
  z-index: 10;
  cursor: pointer;
}

.arrow:hover {
  border-color: gray;
  border-width: 5px;
}

.arrow:before {
  display: block;
  height: 200%;
  width: 200%;
  margin-left: -50%;
  margin-top: -50%;
  content: '';
  transform: rotate(45deg);
}
.arrow.next {
  transform: translateY(-50%) rotate(135deg);
  right: 30px;
}

.arrow.prev {
  transform: translateY(-50%) rotate(-45deg);
  left: 30px;
}
