.info-tag {
  padding: 10px;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: auto;
  transition: all ease-in 0.2s;
  z-index: 2;
  opacity: 0.7;

  width: auto;
  min-width: 170px;

  &:hover {
    opacity: 1;
  }
}

.inner-outline {
  outline: 1px solid rgba(0, 0, 0, 0.7);
  outline-offset: -5px;
  border-radius: 6px;
}

.wrap-text {
  color: black;
  height: auto;
  width: 250px;
  border: none;
  resize: none;
  font-size: 15px;
  background-color: transparent;
  word-break: break-word;
  cursor: context-menu;
  user-select: none;
  margin: 15px 0;
  line-height: 23px;
}

.card-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2eb85c;
  .infotag-title {
    font-size: 15.5px;
    margin-right: 5px;
  }
}
