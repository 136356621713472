.mobi-controller-container {
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1;
  // background-color: #00000061;
  .controller-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .controller-list-item {
      position: relative;
      cursor: pointer;
      transition: 0.4s;
      border-radius: 4px;
      .controller-list-item-popover {
        position: absolute;
        top: 50%;
        transform: translate(10%, -50%);
        right: 100%;
        display: flex;
        align-items: center;
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        .popover-content {
          border-radius: 4px;
          background-color: #000000b0;
          padding: 8px 15px;
          color: #e9e9e9;
          font-size: 14px;
          width: max-content;
        }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-left: 6px solid #000000b0;
          border-bottom: 6px solid transparent;
        }
      }
      .icon {
        width: 45px;
        height: 45px;
        padding: 10px;
        path {
          fill: #fff;
        }
      }
      &:hover {
        .controller-list-item-popover {
          opacity: 1;
          visibility: visible;
          transform: translate(0, -50%);
        }
      }
    }
  }
}