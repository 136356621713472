.menu-bar-icon-container {
  // @media (min-width: 481px) {
  position: absolute;
  bottom: -25px;
  transform: translateY(-50%);
  right: -50px;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 0 10px 10px 0;
  cursor: pointer;
  background-color: rgba(21, 83, 141, 0.5);
  z-index: 1;

  .menu-button {
    width: 28px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }

  .menu-bar {
    width: 100%;
    height: 1px;
    background-color: white;
    border-radius: 2px;
  }

  .menu-open .menu-bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-open .menu-bar:nth-child(2) {
    opacity: 0;
  }

  .menu-open .menu-bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .menu-arrow-left {
    font-size: 20px;
    line-height: 1;
    transition: transform 0.3s ease;
    color: #e81b2a;
  }

  .menu-arrow-down {
    font-size: 20px;
    line-height: 1;
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
  }
}