.floor-plan-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .img-container {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .floor-plan-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .btn-floor-plan {
      padding: 6px 10px;
      color: #333;
      transition: 0.2s;
      border: 1px solid transparent;
      outline: none;
      border-radius: 20px;
      cursor: pointer;
      &.active {
        color: #fff;
        background-color: #104d9d;
      }
      &:hover {
        color: #50abfb;
      }
    }
  }
}
