.info-tag-container {
  position: relative;
  
  .info-tag-dot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #00211A;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .info-tag-content {
    position: absolute;
    bottom: -10px;
    left: -11px;
    display: flex;
    align-items: flex-end;
    transform: rotate(-90deg);
    transform-origin: 0;
    .info-tag-line {
      width: 140px;
      height: 2px;
      background-color: #00211A;
    }
    .info-tag-content-text {
      color: #fff;
      text-transform: uppercase;
      background: rgb(2,0,36);
      background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(2,67,51,1) 0%, rgba(0,33,26,1) 100%);
      padding: 6px 25px;
      width: max-content;
      border-top-left-radius: 50px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      cursor: pointer;
    }
  }
}
