.aqua-modal {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: radial-gradient(circle at 60% 40%, #104d9d 0%, #03325c 65%);
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    position: absolute;
    content: '';
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: url(./bg-body.svg) left bottom !important;
  }

  &-container {
    position: relative;
    height: calc(100vh - 100px);
    width: 960px;
    z-index: 2;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .aqua-modal-iframe__wrapper {
      iframe {
        height: 540px;
        width: 960px;
        position: relative;
      }
    }

    .help-container {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 500px;
      }
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-left-color: #09f;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
    }

    @media (max-width: 767px) {
      height: auto;
      width: auto;
      iframe {
        &.youtube {
          height: 185px;
          width: 325px;
        }

        &.map {
          height: 600px;
          width: 335px;
        }
      }
    }
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    width: auto;
    z-index: 10;
  }

  &-close__btn {
    font-family: EB Garamond, sans-serif;
    display: flex;
    height: 40px;
    color: white;
    padding: 0px 10px;
    line-height: 1.4;
    font-size: 30px;
    border: none;
    cursor: pointer;
    background-color: #0d3e7e;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }
  }
}

.badge-scene {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tour-brandColor);
  opacity: 0.8;
  color: white;
  font-size: 15px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.7);
  transform: translate(-50%, -50%);
  cursor: pointer;

  .scene-title {
    position: absolute;
    bottom: 35px;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    white-space: nowrap;
    color: #fff;
    background-color: #000;
    opacity: 0;
  }

  .scene-title::after {
    content: '';
    position: absolute;
    top: calc(100% - 3px);
    left: 50%;
    transform: translateX(-50%);
    border: 8px solid transparent;
    border-top-color: #000;
  }

  &-text {
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 60px;
  }

  &:hover {
    opacity: 1;
    z-index: 9999;

    .scene-title {
      opacity: 1;
    }
  }

  &-disabled {
    background: gray;

    &:hover {
      background: gray;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
