.image-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    background: rgb(2,0,36);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    &::before {
      opacity: 0.5;
      visibility: visible;
    }
  }
}