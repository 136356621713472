@import '~scss/variables.scss';

.aqua-menu-right__menu {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  .menu-item {
    position: relative;
    cursor: pointer;
    background-color: white;
    padding: 8px;
    border: 1px solid rgba(gray, 0.1);
    transition: 0.2s;
    .scene-group-container,
    .media-group-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(100% + 3px);
      opacity: 0;
      visibility: hidden;
      transition: 0.2s;
      width: 250px;
      max-height: calc(100vh - 200px);
      overflow: auto;
      .scene-group-title {
        svg {
          width: 18px;
          margin-right: 5px;
          path {
            fill: #fff;
          }
        }
        padding: 12px;
        background-color: #052044cc;
        display: flex;
        align-items: center;
        border-bottom: 1px dotted rgba($color: black, $alpha: 0.2);
        color: white;
        font-size: 13px;
        cursor: default;
      }
      .scene-list-items,
      .media-list-items {
        .scene-item,
        .media-item {
          svg {
            width: 12px;
            margin-right: 5px;
            path {
              fill: #03325c;
            }
          }
          padding: 12px;
          background-color: rgba($color: white, $alpha: 0.9);
          border-bottom: 1px dotted rgba($color: black, $alpha: 0.2);
          color: #052044;
          font-size: 13px;
          transition: 0.2s;
          .scene-item-title,
          .media-item-title {
            display: flex;
            align-items: center;
          }
          &:hover {
            background-color: #03325c;
            svg {
              path {
                fill: #fff;
              }
            }
            .scene-item-title,
            .media-item-title {
              color: white;
            }
          }
          &.active {
            background-color: #03325c;
            svg {
              path {
                fill: #fff;
              }
            }
            .scene-item-title,
            .media-item-title {
              color: white;
            }
          }
        }
      }
    }

    &:hover {
      background-color: #03325c;
      img {
        filter: invert(100%) !important;
      }
      .scene-group-container,
      .media-group-container {
        opacity: 1;
        visibility: visible;
      }
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom: 1px solid #03325c;
    }
    &:nth-child(2) {
      border-bottom-left-radius: 10px;
    }
  }
  &.hide-mapfloor-icon {
    .menu-item {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border: none;
    }
  }
}
