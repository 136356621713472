.dropup-container {
  margin: 0 auto;
  width: 130px;
  position: relative;

  .dropup-btn {
    position: relative;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 30px;
    z-index: 2;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
    }

    .global-icon {
      width: 24px;
      height: 24px;
    }

    .language-selected {
      margin-left: 4px;
      color: black;
      font-size: 14px;
      font-weight: 400;
      text-shadow: none;
    }

    .arrow-icon {
      width: 14px;
      height: 14px;
      rotate: 90deg;
      transition: transform 0.3s ease;
    }

    .rotate {
      transform: rotate(90deg);
    }
  }

  .dropup-content {
    position: absolute;
    bottom: 25%;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 30px 30px 20px 20px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
    z-index: 1;
    overflow: hidden;

    .item {
      width: 100%;
      text-align: center;
      text-transform: capitalize;
      padding: 10px;
      cursor: pointer;

      &:last-child {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        background: #d6d6d6;
      }
    }
  }
}
