@import '~scss/variables.scss';

.map-floorplan-container {
  position: absolute;
  right: 50px;
  top: 0px;
  width: 400px;
  transition: 0.4s;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  .map-floorplan-close-btn {
    position: absolute;
    left: -45px;
    cursor: pointer;
    .icon {
      width: 25px;
      height: 25px;
      path {
        fill: #fff;
      }
    }
  }
  img {
    width: 100%;
  }
  .viewpoint-container {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $primary-mobihome;
    cursor: pointer;
    &.current-viewpoint {
      animation: blink-dot 1s linear infinite;
    }
    &.viewpoint {
      animation: blink-dot-opacity 1s linear infinite;
    }
    .viewpoint-title-container {
      position: relative;
      .viewpoint-title {
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        padding: 5px 10px;
        background-color: #00000069;
        border-radius: 4px;
        color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
      }
    }
    &:hover {
      .viewpoint-title-container {
        .viewpoint-title {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &.open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 768px) {
    width: 280px;
  }
  @media (max-width: 500px) {
    width: 200px;
  }
}

@keyframes blink-dot {
  0% {
    box-shadow: 0 0 0 4px $primary-mobihome;
  }
  40% {
    box-shadow: 0 0 0 4px transparent;
  }
  100% {
    box-shadow: 0 0 0 4px $primary-mobihome;
  }
}

@keyframes blink-dot-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
