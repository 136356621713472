.avoriaz-logo-container {
  position: fixed;
  top: 35px;
  left: 35px;
  width: 150px;
  max-height: 150px;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 1024px) {
  .avoriaz-logo-container {
    top: 15px;
    left: 15px;
  }
}