.sharing-links-container {
  position: absolute;
  display: flex;
  right: 50px;
  top: 0;
  background-color: #000000b0;
  border-radius: 4px;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  .external-links-container {
    margin-top: 0;
    .link-item {
      background-color: transparent;
    }
  }
  &.open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}