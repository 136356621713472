.circle {
  position: absolute;
  top: 47%;
  left: 42.5%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
  &::before {
    display: block;
    position: relative;
    content: '';
    width: 5px;
    height: 5px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #fff;
    animation: pulsePoint 1s infinite;
  }
}

@keyframes pulsePoint {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
