@import '~scss/variables.scss';

.avoriaz-hotspot-container {
  transform: translate(-50%, -50%);
  .hotspot-circle {
    position: relative;
    cursor: pointer;
    .hotspot-circle-info {
      height: 60px;
      width: max-content;
      padding-left: 40px;
      padding-right: 15px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      z-index: 0;
      transition: 0.5s;
      background-color: #ffffffbb;
      transform-origin: 0;
      transform: scaleX(0);
      .hotspot-circle-info--group-name {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        .dot-line {
          display: inline-flex;
          align-items: center;
          &::before {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            background-color: $primary-avoriaz;
            border-radius: 50%;
          }
          &::after {
            display: inline-block;
            content: '';
            width: 20px;
            height: 1px;
            background-color: $primary-avoriaz;
          }
        }
      }
      .hotspot-circle-info--scene-name {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
    .hotspot-circle-bg {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: $primary-avoriaz;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 30px;
        height: 30px;
      }
    }
    &:hover {
      .hotspot-circle-info {
        transform: scaleX(1);
      }
    }
  }
}
