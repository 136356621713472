.loading-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding: 15px;
  background: #e7e7e7;
  opacity: 1;
  transition: opacity 2.5s ease-in-out;

  &.fade-out {
    opacity: 0;
  }

  .loading-image {
    width: 200px;
    animation: blink-opacity 3s linear infinite;

    @keyframes blink-opacity {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 10px;
    }

    @media screen and (max-width: 991px) {
      width: 150px;
    }
  }
}
