.aqua-nav-info {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  z-index: 1;

  &-title {
    font-size: 17px;
    color: white;
    // text-shadow: 1px 1px 1px rgba($color: gray, $alpha: 0.5);
    margin-left: 7px;
    text-transform: uppercase;
  }

  &-arrow {
    margin-left: 7px;
    display: flex;
    align-items: center;
  }

  // @media (max-width: 1023px) {
  //   right: 55px;
  // }

  @media (max-width: 575px) {
    top: unset !important;
    bottom: 0;
    right: 0;
    left: 0px;
    width: 100vw;
    margin: 0;
    height: 36px;
    background-color: rgba(37, 88, 138, 0.7);
    backdrop-filter: blur(3px);

    &-title {
      font-size: 10px;
      color: white;
      font-weight: 500;
    }
  }
}