.avoriaz-social-links-container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1;
  .external-links-container {
    padding: 0;
    margin: 0;
    .link-item {
      background-color: transparent;
    }
  }
}

@media (max-width: 1024px) {
  .avoriaz-social-links-container {
    display: none;
  }
}