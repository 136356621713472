@import '~scss/variables.scss';

.avoriaz-menu-container {
  @media (max-width: 1024px) {
    .nav-overview {
      width: 100%;
      left: -100%;
      padding-bottom: 52px;
    }
    .nav-content {
      width: 100%;
      left: 0%;
      z-index: 2;
      background-color: #272727;
      transition: all 0.85s cubic-bezier(0.65, 0, 0.35, 1);
      .nav-content-header {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        height: 60px;
        .nav-content-back-button {
          float: left;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border-right: 1px solid #ebebeb;
          cursor: pointer;
          .back-icon {
            width: 15px;
            height: 15px;
            transform: rotate(180deg);
            path {
              fill: #272727;
            }
          }
        }
        .nav-content-header-title {
          display: flex;
          height: 100%;
          align-items: center;
          margin-right: 60px;
          h1 {
            font-size: 20px;
            font-weight: bold;
            color: $primary-avoriaz;
            margin-left: 10px;
            text-transform: uppercase;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-width: 0;
          }
        }
      }
    }

    &.mobile-show-menu {
      .nav-overview {
        left: 0;
      }
      .nav-content {
        left: 100%;
      }
    }

    .nav-social-links {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      z-index: 2;
      padding: 10px;
      background-color: $primary-avoriaz;
      .external-links-container {
        margin-top: 0;
        gap: 10px;
        .link-item {
          background-color: transparent;
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 512px) {
    .nav-overview {
      ul.nav-overview-list {
        li.nav-overview-list--item {
          font-size: 30px;
        }
      }
    }
  }
}
