.center-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100vw;
}

.full-height {
  height: 100vh;
}

.full-dimentions {
  @extend .full-height;
  @extend .full-width;
}

body {
  font-family: $font-Roboto;
}

.menu-hover-opacity {
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.w-100 {
  width: 100%;
}
