@import '~scss/variables.scss';

$menu-transition-duration: 0.4s;

.mobi-menu-container {
  .bg-close {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: $menu-transition-duration;
    background-color: #0000008a;
    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
  .mobi-logo {
    position: fixed;
    top: 15px;
    left: 15px;
    transform: translateX(0);
    opacity: 1;
    transition: $menu-transition-duration;
    img {
      width: 150px;
    }
    &.open {
      opacity: 0;
      transform: translateX(calc(-100% - 15px));
    }
  }
  .menu-btn {
    position: fixed;
    bottom: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    transform: translateX(0);
    transition: $menu-transition-duration;
    .menu-btn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 10px;
      .icon {
        width: 30px;
        height: 30px;
        path {
          fill: #fff;
          transition: .2s;
        }
        &:hover {
          path {
            fill: $primary-mobihome;
          }
        }
      }
      .close-icon {
        display: none;
      }
    }
    .scene-name {
      font-size: 18px;
      font-weight: 400;
      text-transform: capitalize;
      color: #fff;
    }
    &.open {
      transform: translateX(300px);
      .menu-btn-icon {
        .close-icon {
          display: block;
        }
        .menu-icon {
          display: none;
        }
      }
    }
  }
  .menu-list-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background-color: #000000cf;
    z-index: 5;
    transform: translate(-100%);
    transition: $menu-transition-duration;
    .menu-list-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 10px 0 10px;
      height: 85px;
      img {
        width: 150px;
      }
    }
    .menu-list {
      padding: 15px;
      height: calc(100vh - 85px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 10px;
        border: 3px solid transparent;
        background-clip: padding-box;
      }
      &::-webkit-scrollbar-thumb:hover {
        border-width: 2px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      .menu-list-item {
        width: 100%;
        height: 150px;
        position: relative;
        margin-bottom: 15px;
        border: 3px solid #fff;
        border-radius: 8px;
        overflow: hidden;
        transition: 0.4s;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        .menu-list-item-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .menu-list-item-title {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          padding: 5px;
          background-color: #000000c5;
          color: #fff;
          transition: color 0.3s;
          font-size: 14px;
        }
        &:hover, &.active {
          border-color: $primary-mobihome;
          .menu-list-item-title {
            color: $primary-mobihome;
          }
        }
      }
    }
    &.open {
      transform: translateX(0);
    }
  }
}
