@import '~scss/variables.scss';

.tour-menu {
  @media (max-width: 575px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding: 5px 0 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(51, 103, 163, 0.5);
    backdrop-filter: blur(10px);
    transition: transform 0.3s ease;
    z-index: 2;
    .tour-logo {
      height: 100%;
      .hover-container,
      img {
        height: 100%;
      }
    }
    .menu-items {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      height: calc(100vh - 50px);
      overflow-y: auto;
      background-color: rgba(51, 103, 163, 0.5);
      border-top: 1px solid #ffffff33;
      transform: translateY(-100%);
      transition: 0.2s;
      ul.list-menu-container {
        list-style: none;
        .menu-items-aqua {
          .menu-items-aqua__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            border-bottom: 1px solid #ffffff33;
            .menu-items-aqua__title {
              color: #fff;
              font-size: 12px;
            }
            .menu-items-aqua__icon {
              max-height: 20px;
            }
          }
          // sub-menu
          .menu-items-aqua__scenes {
            max-height: 0;
            overflow: hidden;
            transition: 0.3s;
            ul.scenes-container {
              background-color: #fff;
              .scenes-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                border-bottom: 1px solid #3333331f;
                .scene-preview {
                  display: none;
                }
                &.active {
                  background-color: #052044cc;
                  .scenes-item-title,
                  .icon {
                    color: #fff;
                  }
                }
              }
            }
          }
          &.active {
            background-color: #03325ccc;
            .menu-items-aqua__title {
              color: #f9c468;
            }
            .menu-items-aqua__scenes {
              max-height: 600px; // hard code for transition
            }
          }
        }
      }
      .external-links-container {
        max-width: 250px;
        margin: 60px auto 10px auto;
      }
    }
    .menu-items-aqua__weather {
      position: absolute;
      right: 65px;
      .menu-items-aqua__weather-title {
        color: #fff;
      }
    }
    .menu-bar-icon-container {
      position: relative;
      bottom: unset;
      right: unset;
      transform: unset;
      background-color: #03325ccc;
    }

    &.menu-open {
      // open menu here
      .menu-items {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
}
