@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
// $primary-color: rgb(9, 112, 197);
$primary-color: #f73d3c;
$white-color: #fff;
$dark-grey: #5d5b5b;
$light-grey: #a59595e0;
$text-grey: #b6b4b5;
$black-color: #000000;
$blue-color: #368dff;
$menu_bg_color: rgba(0, 0, 0, 0.75);
$menu_header_bg_color: rgba(0, 0, 0, 0.3);
$menu_close_bg_color: rgba(0, 0, 0, 0.85);
$highlight_bg_color: rgba(255, 255, 255, 0.1);
$intro_bg_color: rgba(255, 255, 255, 0.85);
$grey_color: #666666;
$light_green: #a6ce39;
$boxshadow_black: #0000001a;
$menu_line_color: rgba(255, 255, 255, 0.14);
$pan_menu_active_bg: rgba(166, 206, 57, 0.6);
$primary-avoriaz:#ffb36d;
$primary-mobihome: rgb(94, 94, 211);
$primary-resort: #2B3B4C;

$size-min-sm: 576px;
$size-min-md: 768px;
$size-min-lg: 992px;
$size-min-xl: 1200px;

$size-max-sm: 425px;
$size-max-md: 768px;
$size-max-lg: 1024px;
$size-max-xl: 1440px;
$size-max-2xl: 2560px;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-Roboto: 'Roboto', sans-serif;
