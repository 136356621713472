@import '~scss/variables.scss';

.avoriaz-menu-button-container {
  height: 60px;
  width: 60px;
  position: fixed;
  top: 35px;
  right: 35px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  animation: close 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  .nav-menu {
    width: 100%;
    height: 100%;
    background: $primary-avoriaz;
    background: linear-gradient(to right, #56607a 50%, $primary-avoriaz 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    animation: background-off 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    .nav-menu-button {
      height: 20px;
      left: 50%;
      margin-top: -10px;
      margin-left: -2px;
      position: absolute;
      top: 50%;
      transform: scale(1) rotate(0deg);
      transform-origin: left;
      transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1) 0.35s;
      width: 20px;
      .nav-menu-button-icon {
        background: #fff;
        content: '';
        display: block;
        height: 4px;
        position: absolute;
        transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
        width: 4px;
        &::before,
        &::after {
          background: #fff;
          content: '';
          display: block;
          height: 4px;
          position: absolute;
          transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
          width: 4px;
        }
        &.nav-menu-button-icon-1 {
          top: 0;
          &::before {
            left: -8px;
          }
          &::after {
            right: -8px;
          }
        }
        &.nav-menu-button-icon-2 {
          top: 8px;
          &::before {
            left: -8px;
          }
          &::after {
            right: -8px;
          }
        }
        &.nav-menu-button-icon-3 {
          bottom: 0;
          &::before {
            left: -8px;
          }
          &::after {
            right: -8px;
          }
        }
      }
    }
    .close-menu-button {
      height: 16px;
      position: absolute;
      left: 50%;
      margin-left: -8px;
      margin-top: -8px;
      top: 50%;
      transform: scale(0.01);
      transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      width: 16px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        margin-left: 3px;
        width: 14px;
        height: 3px;
        background: #fff;
        transform: scaleX(0);
        transform-origin: 100% 100%;
        transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      }
      .close-menu-button-icon {
        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 3px;
          background: #fff;
          top: 7px;
        }
        &::before {
          transform: rotate(-45deg);
          transition: transform 0.5s;
        }
        &::after {
          transform: rotate(45deg);
          transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
      }
    }
  }
  @media (min-width: 1024px) {
    &:hover {
      .nav-menu {
        animation: background-on 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
        .nav-menu-button {
          .nav-menu-button-icon {
            &.nav-menu-button-icon-1 {
              transform: translate3d(-10px, -2px, 0);
              &::before {
                transform: translate3d(8px, 10px, 0);
              }
              &::after {
                transform: translate3d(2px, 0, 0);
              }
            }
            &.nav-menu-button-icon-2 {
              &::before {
                transform: translate3d(-2px, 10px, 0);
              }
              &::after {
                transform: translate3d(2px, -10px, 0);
              }
            }
            &.nav-menu-button-icon-3 {
              transform: translate3d(10px, 2px, 0);
              &::before {
                transform: translate3d(-2px, 0, 0);
              }
              &::after {
                transform: translate3d(-8px, -10px, 0);
              }
            }
          }
        }
        .close-menu-button {
          &::before {
            transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1) 0s;
            transform: scaleX(1);
            transform-origin: 0 0;
          }
          .close-menu-button-icon {
            &::before {
              transform: rotate(-45deg) translateY(-5px) scaleX(0.7) !important;
              transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
            }
            &::after {
              transform: rotate(45deg) translateY(5px) scaleX(0.7) !important;
              transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
            }
          }
        }
      }
    }
  }

  &.open {
    animation: open 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards !important;
    .nav-menu {
      .nav-menu-button {
        transform: scale(0.01) rotate(45deg);
        transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
      .close-menu-button {
        transform: scale(1);
        transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1) 0.35s;
      }
    }
  }
}

@keyframes open {
  0% {
    right: 35px;
    top: 35px;
    transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(-10deg, -10deg);
  }
  100% {
    right: 0px;
    top: 0px;
    transform: skew(0deg, 0deg);
  }
}

@-webkit-keyframes open {
  0% {
    right: 35px;
    top: 35px;
    transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(-10deg, -10deg);
  }
  100% {
    right: 0px;
    top: 0px;
    transform: skew(0deg, 0deg);
  }
}

@keyframes close {
  0% {
    right: 0px;
    top: 0px;
    transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(-10deg, -10deg);
  }
  100% {
    right: 35px;
    top: 35px;
    transform: skew(0deg, 0deg);
  }
}

@-webkit-keyframes close {
  0% {
    right: 0px;
    top: 0px;
    transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(-10deg, -10deg);
  }
  100% {
    right: 35px;
    top: 35px;
    transform: skew(0deg, 0deg);
  }
}

@keyframes background-off {
  0% {
    background: linear-gradient(to right, $primary-avoriaz 50%, #56607a 50%);
    background-size: 200% 100%;
    background-position: right bottom;
  }
  100% {
    background: linear-gradient(to right, $primary-avoriaz 50%, #56607a 50%);
    background-size: 200% 100%;
    background-position: left bottom;
  }
}

@-webkit-keyframes background-off {
  0% {
    background: linear-gradient(to right, $primary-avoriaz 50%, #56607a 50%);
    background-size: 200% 100%;
    background-position: right bottom;
  }
  100% {
    background: linear-gradient(to right, $primary-avoriaz 50%, #56607a 50%);
    background-size: 200% 100%;
    background-position: left bottom;
  }
}

@keyframes background-on {
  100% {
    background: linear-gradient(to right, #56607a 50%, $primary-avoriaz 50%);
    background-size: 200% 100%;
    background-position: left bottom;
  }
}

@-webkit-keyframes background-on {
  100% {
    background: linear-gradient(to right, #56607a 50%, $primary-avoriaz 50%);
    background-size: 200% 100%;
    background-position: left bottom;
  }
}
