.Popup-content-gallery {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .slider-wrapper {
    ul {
      overflow: unset;
    }
    li {
      height: 84vh;
      background-color: transparent;
      border: none;
    }
  }
}
