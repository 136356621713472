@import '~scss/variables.scss';

.tour-menu {
  @media (min-width: $size-min-sm) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 260px;
    height: 100vh;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(51, 103, 163, 0.5);
    backdrop-filter: blur(10px);
    transition: transform 0.3s ease;
    z-index: 2;
    .tour-logo {
      img {
        width: 175px;
        height: 135px;
        object-fit: contain;
      }
    }
    ul.list-menu-container {
      list-style: none;
    }
    li.menu-items-aqua {
      width: 100%;
      position: relative;
      border-top: 1px solid rgba($color: white, $alpha: 0.2);
      cursor: pointer;
      .menu-items-aqua__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 15px;
        .menu-items-aqua__title {
          text-transform: uppercase;
          color: white;
          font-size: 13px;
          transition: 0.3s ease;
        }
        .menu-items-aqua__icon {
          height: 20px;
          transition: 0.3s ease;
        }
      }
      .menu-items-aqua__scenes {
        position: absolute;
        top: 0;
        left: 260px;
        transition: 0.5s ease;
        opacity: 0;
        visibility: hidden;
        z-index: 2;
        ul.scenes-container {
          position: relative;
          .scenes-item {
            width: 300px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 15px;
            background-color: #ffffffcc;
            transition: 0.2s;
            border-bottom: 1px solid #3333331f;
            .scenes-item-title {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 14px;
              color: #052044;
              transition: 0.2s;
              text-transform: capitalize;
            }
            .icon {
              font-size: 14px;
            }
            .scene-preview {
              opacity: 0;
              visibility: hidden;
              transition: 0.1s;
              position: absolute;
              top: 0;
              left: calc(100% + 1px);
              background-color: #fff;
              padding: 5px;
              .img-container {
                width: 300px;
                height: 180px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .description {
                margin-top: 15px;
                margin-bottom: 5px;
                font-size: 14px;
              }
            }
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background-color: #052044cc;
              .scenes-item-title,
              .icon {
                color: #fff;
              }
              .scene-preview {
                visibility: visible;
                opacity: 1;
              }
            }
            &.active {
              background-color: #052044cc;
              .scenes-item-title,
              .icon {
                color: #fff;
              }
            }
          }
        }
      }
      &:last-child {
        border-bottom: 1px solid rgba($color: white, $alpha: 0.2);
      }
      &:hover {
        .menu-items-aqua__container {
          .menu-items-aqua__title {
            color: #f9c468;
          }
        }
        .menu-items-aqua__scenes {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .menu-items-aqua__weather {
      width: 100%;
      position: absolute;
      bottom: 0;
      margin: 15px;
      text-align: center;
      transition: 0.3s ease;
      &.translateX {
        transform: translateX(300px);
      }
      .menu-items-aqua__weather-title {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        color: #f9c468;
        text-shadow: 1.5px 1px 1px rgba($color: gray, $alpha: 0.5);

        &.changeColor {
          color: white;
        }
        i {
          font-size: 20px;
          margin: 0 6px;
        }
      }
    }
    &.menu-close {
      transform: translateX(-100%);
      .menu-toggle {
        .menu-arrow-left {
          transform: rotate(180deg);
        }
        .menu-arrow-down {
          transform: rotate(90deg);
        }
      }
    }
    &.menu-open {
      transform: translateX(0);
    }
  }
}
