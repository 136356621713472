.aquaHotspotFrame {
  border-radius: 10px;
  transform: translateY(-20px);
  cursor: pointer;
  &-container {
    width: auto;
    height: auto;
    text-align: center;
    padding: 10px 15px;
  }

  &-title,
  &-subtitle {
    text-transform: uppercase;
    white-space: nowrap;
  }

  &-subtitle {
    margin-top: 5px;
  }

  &-imgC {
    margin-top: 5px;
    img {
      max-width: 80px;
      max-height: 40px;
      object-fit: cover;
    }
  }
}
