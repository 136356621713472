@import '~scss/variables.scss';

.resort-menu-container {
  position: fixed;
  top: 20px;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  .scene-name-container {
    padding: 0 15px;
    .scene-name-title {
      color: #fff;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 1px;
      text-shadow: 2px 2px 3px #000000d8;
    }
    .scene-name {
      color: #fff;
      text-transform: uppercase;
      text-shadow: 2px 2px 3px #000000d8;
    }
  }

  .mobile-only {
    display: none;
  }

  .menu-list {
    display: flex;
    align-items: center;
    .menu-list-item {
      position: relative;
      &.mobile-title {
        display: none;
      }
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        bottom: 0;
        height: 3px;
        background-color: #fff;
        transition: 0.3s;
      }
      .menu-list-item-title {
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 10px 20px;
        background-color: $primary-resort;
        color: #fff;
        .icon {
          width: 10px;
          height: 10px;
          margin-left: 5px;
          path {
            fill: #fff;
          }
        }
      }
      .submenu-list {
        position: absolute;
        top: 100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        padding-top: 10px;
        .submenu-list-item {
          width: 200px;
          .submenu-list-item-title {
            padding: 10px 20px;
            background-color: $primary-resort;
            font-weight: normal;
            letter-spacing: 1px;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            text-transform: uppercase;
            border-bottom: 1px solid #fff;
          }
          &:last-child {
            .submenu-list-item-title {
              border-bottom: 0;
            }
          }
        }
      }
      &.social-links {
        .menu-list-item-title {
          background-color: #fff;
          color: $primary-resort;
          font-weight: bold;
        }
        .submenu-list {
          right: 0;
          left: unset;
          .external-links-container {
            background-color: $primary-resort;
            margin-top: 0;
            padding: 10px 15px;
            .link-item {
              background-color: transparent;
            }
          }
        }
      }
      &:hover {
        &::after {
          width: 100%;
        }
        .submenu-list {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  @media (max-width: 1060px) {
    .menu-btn-toggle {
      display: flex;
      padding: 5px;
      cursor: pointer;
      margin-right: 15px;
      .icon {
        width: 35px;
        height: 35px;
        path {
          fill: #fff;
        }
      }
    }
    .menu-list-bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &.open-on-mobile {
        display: block;
      }
    }
    .menu-list {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: #fff;
      flex-direction: column;
      width: 300px;
      transform: translateX(-100%);
      transition: 0.3s;
      .menu-list-item {
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        &:last-child {
          border-bottom: 0;
        }
        &::after {
          display: none;
        }
        &.mobile-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .menu-list-item-title {
            text-transform: uppercase;
            color: #939393;
            font-weight: bold;
          }
          .icon {
            width: 25px;
            height: 25px;
            padding: 5px;
            border-radius: 50%;
            background-color: #da1a35;
            margin-right: 15px;
            cursor: pointer;
            path {
              fill: #fff;
            }
          }
        }
        .menu-list-item-title {
          background-color: transparent;
          color: $primary-resort;
          transition: 0.3s;
          .icon {
            path {
              transition: 0.3s;
              fill: $primary-resort;
            }
          }
          &:hover {
            background-color: #939393;
            color: #fff;
            .icon {
              path {
                fill: #fff;
              }
            }
          }
        }
        .submenu-list {
          position: unset;
          padding-top: 0;
          opacity: 1;
          visibility: visible;
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.4s;
          .submenu-list-item {
            width: 100%;
            .submenu-list-item-title {
              background-color: transparent;
              color: $primary-resort;
              transition: 0.3s;
              font-size: 12px;
              .icon {
                path {
                  transition: 0.3s;
                  fill: $primary-resort;
                }
              }
              &:hover {
                background-color: #939393;
                color: #fff;
                .icon {
                  path {
                    fill: #fff;
                  }
                }
              }
            }
          }
        }
        &.open-on-mobile {
          .submenu-list {
            max-height: 500px;
          }
        }
        &.social-links {
          .menu-list-item-title {
            display: none;
          }
          .submenu-list {
            left: 0;
            opacity: 1;
            visibility: visible;
            .external-links-container {
              margin-top: 0;
              padding: 10px 15px;
              background-color: transparent;
              .link-item {
                border-color: $primary-resort;
                path {
                  fill: $primary-resort;
                }
              }
            }
          }
        }
      }

      &.open-on-mobile {
        transform: translateX(0);
      }
    }
  }
}
