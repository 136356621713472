.external-links-container {
  margin-top: 60px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  .link-item {
    display: inline-block;
    height: 32px;
    width: 32px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    background-color: #03325c;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: 5px;
    transition: background-color 0.2s;
    &:last-child{
      margin-right: 0;
    }
    svg {
      width: 18px;
      height: 18px;
      path {
        transition: 0.4s;
        fill: #d1d1d1;
      }
    }
    &:hover {
      background-color: #011c33;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
