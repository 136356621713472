@import '~scss/variables.scss';

.avoriaz-menu-container {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(0%);
  background-color: #272727c9;
  transition: all 0.85s cubic-bezier(0.65, 0, 0.35, 1);
  z-index: 5;
  .mobile-only {
    display: none;
  }
  .nav-overview {
    background-color: #fafafa;
    background: url("./../../../assets/images/avoriaz-nav-background.jpg");
    overflow-y: auto;
    display: flex;
    align-items: center;
    animation: nav-overview 0.5s cubic-bezier(0.65, 0, 0.35, 1) 0.25s forwards;
    position: absolute;
    height: 100%;
    left: -50%;
    width: 50%;
    z-index: 2;
    ul.nav-overview-list {
      list-style-type: none;
      padding: 20px 40px;
      li.nav-overview-list--item {
        font-size: 40px;
        font-weight: bolder;
        text-transform: uppercase;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(
          to left,
          #333,
          #333 50%,
          $primary-avoriaz 50%
        );
        background-clip: text;
        background-position: 100%;
        background-size: 200% 100%;
        transition: background-position 0.5s cubic-bezier(0.65, 0, 0.35, 1);
        opacity: 0;
        &:nth-child(1) {
          animation: nav-overview-list-item 0.5s cubic-bezier(0.65, 0, 0.35, 1)
            forwards;
        }
        &:nth-child(2) {
          animation: nav-overview-list-item 0.5s cubic-bezier(0.65, 0, 0.35, 1)
            0.2s forwards;
        }
        &:nth-child(3) {
          animation: nav-overview-list-item 0.5s cubic-bezier(0.65, 0, 0.35, 1)
            0.4s forwards;
        }
        &:nth-child(4) {
          animation: nav-overview-list-item 0.5s cubic-bezier(0.65, 0, 0.35, 1)
            0.6s forwards;
        }
        &:nth-child(n + 5) {
          animation: nav-overview-list-item 0.5s cubic-bezier(0.65, 0, 0.35, 1)
            0.8s forwards;
        }
        .nav-overview-list--item-icon {
          width: 0;
          height: 30px;
          transition: width 0.5s cubic-bezier(0.65, 0, 0.35, 1);
          path {
            fill: #333;
            transition: fill 0.5s;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover,
        &.active {
          background-position: 0% 100%;
          .nav-overview-list--item-icon {
            width: 30px;
            path {
              fill: $primary-avoriaz;
            }
          }
        }
      }
    }
  }
  .nav-content {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 50%;
    display: flex;
    align-items: center;
    ul.nav-content-list {
      list-style-type: none;
      padding: 20px 40px;
      li.nav-content-list--item {
        position: relative;
        font-size: 20px;
        font-weight: bolder;
        text-transform: capitalize;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(
          to left,
          #fff,
          #fff 50%,
          $primary-avoriaz 50%
        );
        background-clip: text;
        background-position: 100%;
        background-size: 200% 100%;
        transition: background-position 0.5s cubic-bezier(0.65, 0, 0.35, 1);
        opacity: 0;
        &::after {
          content: '';
          background: $primary-avoriaz;
          height: 100%;
          right: 0px;
          position: absolute;
          top: 0px;
          width: 100%;
        }
        &.slide-in {
          &:nth-child(1) {
            animation: 0.75s nav-content-list-item forwards;
            &::after {
              animation: 0.75s nav-content-list-item-after forwards;
            }
          }
          &:nth-child(2) {
            animation: 0.75s nav-content-list-item 0.2s forwards;
            &::after {
              animation: 0.75s nav-content-list-item-after 0.2s forwards;
            }
          }
          &:nth-child(3) {
            animation: 0.75s nav-content-list-item 0.4s forwards;
            &::after {
              animation: 0.75s nav-content-list-item-after 0.4s forwards;
            }
          }
          &:nth-child(4) {
            animation: 0.75s nav-content-list-item 0.6s forwards;
            &::after {
              animation: 0.75s nav-content-list-item-after 0.6s forwards;
            }
          }
          &:nth-child(n + 5) {
            animation: 0.75s nav-content-list-item 0.8s forwards;
            &::after {
              animation: 0.75s nav-content-list-item-after 0.8s forwards;
            }
          }
        }
        .nav-content-list--item-dot {
          display: table;
          margin-bottom: 6px;
          position: relative;
          width: 28px;
          &::before {
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 8px;
            margin-bottom: 3px;
            position: absolute;
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
              opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            width: 8px;
          }
          &::after {
            background: $primary-avoriaz;
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 8px;
            left: 1px;
            opacity: 0;
            position: absolute;
            top: 1px;
            transform: scale(0);
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
              opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            width: 8px;
          }
        }
        &:hover,
        &.active {
          background-position: 0% 100%;
          .nav-content-list--item-dot {
            &::before {
              opacity: 0;
              transform: scale(1.8);
              transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1),
                opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
            &::after {
              opacity: 1;
              transform: scale(1);
              transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1)
                  0.15s,
                opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) 0.15s;
            }
          }
        }
      }
    }
  }
  &.hidden {
    transform: translateX(-100%);
    .nav-overview {
      animation: nav-overview-hidden 0.5s cubic-bezier(0.65, 0, 0.35, 1)
        forwards;
    }
    .nav-content {
      transition: opacity 0.5s cubic-bezier(0.65, 0, 0.35, 1) 0s;
    }
  }
}

@keyframes nav-overview {
  0% {
    left: -50%;
  }
  100% {
    left: 0%;
  }
}

@-webkit-keyframes nav-overview {
  0% {
    left: -50%;
  }
  100% {
    left: 0%;
  }
}

@keyframes nav-overview-hidden {
  0% {
    left: 0%;
  }
  100% {
    left: -50%;
  }
}

@-webkit-keyframes nav-overview-hidden {
  0% {
    left: 0%;
  }
  100% {
    left: -50%;
  }
}

@keyframes nav-content-list-item-after {
  0% {
    left: 0px;
    right: auto;
    width: 0%;
  }

  50% {
    left: 0px;
    right: auto;
    width: 100%;
  }
  51% {
    left: auto;
    right: 0px;
    width: 100%;
  }
  100% {
    left: auto;
    right: 0px;
    width: 0%;
  }
}

@-webkit-keyframes nav-content-list-item-after {
  0% {
    left: 0px;
    right: auto;
    width: 0%;
    opacity: 0;
  }

  50% {
    left: 0px;
    right: auto;
    width: 100%;
    opacity: 1;
  }
  51% {
    left: auto;
    right: 0px;
    width: 100%;
  }
  100% {
    left: auto;
    right: 0px;
    width: 0%;
  }
}

@keyframes nav-content-list-item {
  0% {
    margin-left: -25px;
    opacity: 0;
  }

  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@-webkit-keyframes nav-content-list-item {
  0% {
    margin-left: -25px;
    opacity: 0;
  }

  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes nav-overview-list-item {
  0% {
    margin-left: -10px;
    opacity: 0;
  }

  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@-webkit-keyframes nav-overview-list-item {
  0% {
    margin-left: -10px;
    opacity: 0;
  }

  100% {
    margin-left: 0px;
    opacity: 1;
  }
}
