.resort-controller-container {
  position: fixed;
  bottom: 15px;
  right: 25px;
  .controller-list {
    display: flex;
    align-items: center;
    gap: 10px;
    .controller-list-item {
      position: relative;
      cursor: pointer;
      width: 35px;
      height: 35px;
      border: 2px solid #dddddd;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: border 0.3s;
      .controller-tooltip {
        position: absolute;
        bottom: calc(100% + 10px);
        opacity: 0;
        visibility: hidden;
        width: 120px;
        padding: 5px 10px;
        transition: 0.3s;
        background-color: #fff;
        text-align: center;
        transform: translateY(10px);
        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-top: 4px solid #fff;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
      }
      .icon {
        width: 20px;
        height: 20px;
        path {
          fill: #dddddd;
          transition: fill 0.3s;
        }
      }
      &:last-child {
        .controller-tooltip {
          right: -20px;
          &::after {
            left: calc(50% + 25px);
          }
        }
      }
      &:hover {
        border-color: #fff;
        .controller-tooltip {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
        .icon {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}