@import '~scss/variables.scss';

.avoriaz-scene-info-container {
  position: fixed;
  bottom: 35px;
  left: 35px;
  z-index: 2;
  pointer-events: none;
  .dot-line {
    display: inline-flex;
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      width: 9px;
      height: 9px;
      background-color: $primary-avoriaz;
      border-radius: 50%;
    }
    &::after {
      display: inline-block;
      content: "";
      width: 80px;
      height: 1px;
      background-color: $primary-avoriaz;
    }
  }
  .scene-title {
    color: #fff;
    font-size: 40px;
    text-transform: capitalize;
  }
  .group-title {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    pointer-events: visible;
    &:hover {
      text-decoration: underline;
    }
  }
  .info-tag-description {
    color: #fff;
    font-size: 12px;
    max-width: 500px;
    margin-top: 5px;
  }
}

@media (max-width: 1024px) {
  .avoriaz-scene-info-container {
    bottom: 15px;
    left: 15px;
  }
}