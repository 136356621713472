@import '~scss/variables.scss';

.resort-floorplan-container {
  .floorplan-btn-container {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    .floorplan-btn {
      font-size: 14px;
      color: #fff;
      padding: 10px 15px;
      max-width: 130px;
      cursor: pointer;
      margin-bottom: 2px;
      .icon {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        &.icon-floorplan {
          transform: rotate(-90deg);
        }
        path {
          fill: #fff;
        }
      }
      &.floorplan-btn-item {
        background-color: $primary-resort;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &.floorplan-btn-toggle-menu {
        background-color: #48637f;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .floorplan-img-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: scale(0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    .img-container {
      width: 100%;
      height: 100%;
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &.open {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }

  .viewpoint-container {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $primary-mobihome;
    cursor: pointer;
    &.current-viewpoint {
      animation: blink-dot 1s linear infinite;
    }
    &.viewpoint {
      animation: blink-dot-opacity 1s linear infinite;
    }
    .viewpoint-title-container {
      position: relative;
      .viewpoint-title {
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        padding: 5px 10px;
        background-color: #00000069;
        border-radius: 4px;
        color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
      }
    }
    &:hover {
      .viewpoint-title-container {
        .viewpoint-title {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  @keyframes blink-dot {
    0% {
      box-shadow: 0 0 0 4px $primary-mobihome;
    }
    40% {
      box-shadow: 0 0 0 4px transparent;
    }
    100% {
      box-shadow: 0 0 0 4px $primary-mobihome;
    }
  }

  @keyframes blink-dot-opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
