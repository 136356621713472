@import '~scss/variables.scss';

@media (max-width: 1024px) {
  .avoriaz-menu-button-container {
    top: 15px;
    right: 15px;
  }

  @keyframes open {
    0% {
      right: 15px;
      top: 15px;
      transform: skew(0deg, 0deg);
    }
    50% {
      transform: skew(-10deg, -10deg);
    }
    100% {
      right: 0px;
      top: 0px;
      transform: skew(0deg, 0deg);
    }
  }

  @-webkit-keyframes open {
    0% {
      right: 15px;
      top: 15px;
      transform: skew(0deg, 0deg);
    }
    50% {
      transform: skew(-10deg, -10deg);
    }
    100% {
      right: 0px;
      top: 0px;
      transform: skew(0deg, 0deg);
    }
  }

  @keyframes close {
    0% {
      right: 0px;
      top: 0px;
      transform: skew(0deg, 0deg);
    }
    50% {
      transform: skew(-10deg, -10deg);
    }
    100% {
      right: 15px;
      top: 15px;
      transform: skew(0deg, 0deg);
    }
  }

  @-webkit-keyframes close {
    0% {
      right: 0px;
      top: 0px;
      transform: skew(0deg, 0deg);
    }
    50% {
      transform: skew(-10deg, -10deg);
    }
    100% {
      right: 15px;
      top: 15px;
      transform: skew(0deg, 0deg);
    }
  }
}
